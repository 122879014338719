<header class="header">
    <nav>
        <div class="logo-Container">
            <img  src="assets/images/brand-logo2.png" class="tb-logo tb-cursor" (click)="home()"/>
        </div>
        <!-- <span style="background: red;">hola</span> -->
        <!-- <div class="contente-a">
            <span></span>
            <a class="xs-none" href="list/benefits/{{uuidOrganization}}">Home</a>
            <a class="xs-none" target="_blank" href="https://rhodium.ooo/">Visita nuestra página oficial</a>

            <button mat-icon-button *ngIf="showMenu" [matMenuTriggerFor]="menu">
                <mat-icon>
                    menu
                </mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a mat-menu-item class="" href="list/benefits/{{uuidOrganization}}">Home</a>
                <a mat-menu-item class="" target="_blank" href="https://rhodium.ooo/">Visita nuestra página oficial</a>
            </mat-menu>
        </div> -->
    </nav>
</header>